<template>
  <v-container>
    <h1><textloader-component textKey="OmFRubrik"/> </h1>
    <paragraph-component><textloader-component textKey="OmFBrödtext"/> </paragraph-component>
  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  }
}
</script>
